module.exports = [
  {
    path: '/mobile/work/:pagetime?',
    pattern: /^\/mobile\/work(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/work.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/work.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/mine/:pagetime?',
    pattern: /^\/mobile\/mine(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/mine.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/mine.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/worker/:pagetime?',
    pattern: /^\/mobile\/worker(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/worker.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/worker.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/user/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/user(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/user.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/user.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/userDialog/register/:pagetime?',
    pattern: /^\/mobile\/userDialog\/register(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/userDialog/register.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/userDialog/register.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/userDialog/twofactorlogin/:pagetime?',
    pattern: /^\/mobile\/userDialog\/twofactorlogin(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/userDialog/twofactorlogin.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/userDialog/twofactorlogin.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/dialog/taskList/:pagetime?',
    pattern: /^\/mobile\/dialog\/taskList(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/dialog/taskList.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/dialog/taskList.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/index/:pagetime?',
    pattern: /^\/mobile\/index(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/index.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/index.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/userDialog/authorize/:pagetime?',
    pattern: /^\/mobile\/userDialog\/authorize(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/userDialog/authorize.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/userDialog/authorize.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/authorize/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/authorize(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/authorize.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/authorize.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/main/:pagetime?',
    pattern: /^\/mobile\/main(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/main.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/main.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/userDialog/changePassword/:pagetime?',
    pattern: /^\/mobile\/userDialog\/changePassword(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/userDialog/changePassword.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/userDialog/changePassword.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/dialog/loginLoading/:pagetime?',
    pattern: /^\/mobile\/dialog\/loginLoading(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/dialog/loginLoading.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/dialog/loginLoading.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/userDialog/user/:pagetime?',
    pattern: /^\/mobile\/userDialog\/user(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/userDialog/user.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/userDialog/user.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/userDialog/bindphone/:pagetime?',
    pattern: /^\/mobile\/userDialog\/bindphone(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/userDialog/bindphone.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/userDialog/bindphone.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/org/:pagetime?',
    pattern: /^\/mobile\/org(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/org.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/org.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/bindphone/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/bindphone(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/bindphone.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/bindphone.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/infoPublish/infoPub/:pagetime?',
    pattern: /^\/mobile\/infoPublish\/infoPub(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/infoPublish/infoPub.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/infoPublish/infoPub.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/register/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/register(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/register.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/register.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/dialog/loginConfirm/:pagetime?',
    pattern: /^\/mobile\/dialog\/loginConfirm(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/dialog/loginConfirm.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/dialog/loginConfirm.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/entorgsm/components/orgSelect/dialog/orgDialog/:pagetime?',
    pattern: /^\/comp\/entorgsm\/components\/orgSelect\/dialog\/orgDialog(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/entorgsm/components/orgSelect/dialog/orgDialog.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/entorgsm/components/orgSelect/dialog/orgDialog.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/twofactorlogin/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/twofactorlogin(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/twofactorlogin.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/twofactorlogin.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/infoPublish/infoView/:pagetime?',
    pattern: /^\/mobile\/infoPublish\/infoView(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/infoPublish/infoView.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/infoPublish/infoView.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/login/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/login(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/login.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/login.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/userDialog/forgetPassword/:pagetime?',
    pattern: /^\/mobile\/userDialog\/forgetPassword(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/userDialog/forgetPassword.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/userDialog/forgetPassword.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/miniProgram/page/:pagetime?',
    pattern: /^\/mobile\/miniProgram\/page(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/miniProgram/page.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/miniProgram/page.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/infoPublish/infoDetail/:pagetime?',
    pattern: /^\/mobile\/infoPublish\/infoDetail(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/infoPublish/infoDetail.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/infoPublish/infoDetail.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/mobile/userDialog/login/:pagetime?',
    pattern: /^\/mobile\/userDialog\/login(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/mobile/userDialog/login.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/mobile/userDialog/login.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
];