import React, { Component, PropTypes } from 'react';
import ReactDom from 'react-dom';
import './app'; 
 import './app.css'; 
 class Index extends Component {
		constructor(...args){
			super(...args);
			if (this.app && this.app.onLaunch && (typeof this.app.onLaunch == 'function')){
				this.app.onLaunch();
			}
		}
		componentWillMount() {
			if (this.app && this.app.onShow && (typeof this.app.onShow == 'function')){
				this.app.onShow();
			}
		}
		render() {
			return null;
		}
}
export default ReactDom.render(<Index />, document.querySelector('#app'))