import {initenv} from './wxsys/lib/base/initenv';
 wx.App = {baseUrl: '', resPath: ''};
initenv();

App({
	
})
import '../core/framework/app';
import AppConfig from './app.json';
getApp().setAppConfig(AppConfig);
